<template>
  <ValidationObserver v-slot="{ invalid }" ref="valobs">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('New Environment Details') }}</p>
      </header>
      <section class="modal-card-body">
        <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
        <span @click="envOfscHelp = !envOfscHelp">
          <b-icon icon="question-circle" size="is-small"></b-icon>
        </span>
        <b-tooltip
          :label="
            $t(
              'The name of the OFSC environment - usually companyname, company.test or companyname.test2',
            )
          "
          :active="envOfscHelp"
          always
          multilined
          position="is-bottom"
          type="is-dark"
        >
          <span class="bold">OFSC Environment Name</span>
        </b-tooltip>
        <ValidationProvider
          v-slot="{ errors }"
          name="Environment OFSC mame"
          rules="required|validEnvName"
        >
          <b-input v-model="name" type="text"></b-input>
          <p style="min-height: 1rem" class="help is-danger">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
        <span @click="envFriendHelp = !envFriendHelp">
          <b-icon icon="question-circle" size="is-small"></b-icon>
        </span>
        <b-tooltip
          :label="$t(`environment-name-hint`)"
          :active="envFriendHelp"
          always
          multilined
          position="is-right"
          type="is-dark"
        >
          <span class="bold">{{ $t('Friendly name') }}</span>
        </b-tooltip>
        <ValidationProvider
          v-slot="{ errors }"
          name="Friendly name"
          rules="required"
        >
          <b-input v-model="friendlyName" type="text"></b-input>
          <span style="min-height: 1rem" class="help is-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
        <span @click="envProdHelp = !envProdHelp">
          <b-icon icon="question-circle" size="is-small"></b-icon>
        </span>
        <b-tooltip
          :label="
            $t(
              'Setting this to true will prevent the environment being available for data to be loaded into',
            )
          "
          :active="envProdHelp"
          always
          multilined
          position="is-right"
          type="is-dark"
        >
          <span class="bold">{{ $t('Prevent data loads/production') }}</span>
        </b-tooltip>
        <b-field style="padding-top: 5px">
          <b-switch v-model="production"></b-switch>
        </b-field>
        <span @click="envUserHelp = !envUserHelp">
          <b-icon icon="question-circle" size="is-small"></b-icon>
        </span>
        <b-tooltip
          :label="$t('The application/username to use to connect to OFSC')"
          :active="envUserHelp"
          always
          multilined
          position="is-right"
          type="is-dark"
        >
          <span class="bold">{{
            $t('OFSC Integration User/Application')
          }}</span>
        </b-tooltip>
        <ValidationProvider
          v-slot="{ errors }"
          name="Username"
          rules="required"
        >
          <b-input v-model="username" name="Username" type="text"></b-input>
          <span style="min-height: 1rem" class="help is-danger">
            {{ errors[0] }}
          </span>
        </ValidationProvider>
        <span @click="envPasswordHelp = !envPasswordHelp">
          <b-icon icon="question-circle" size="is-small"></b-icon>
        </span>
        <b-tooltip
          :label="$t(`The password/secret to use to connect to OFSC`)"
          :active="envPasswordHelp"
          always
          multilined
          position="is-right"
          type="is-dark"
        >
          <span class="bold">{{ $t('OFSC Integration Secret') }}</span>
        </b-tooltip>
        <ValidationProvider
          v-slot="{ errors }"
          name="Password"
          rules="required"
        >
          <b-input
            v-model="password"
            name="Password"
            type="password"
            password-reveal
          ></b-input>

          <div style="min-height: 1rem" class="help is-danger">
            {{ errors[0] }}
          </div>
        </ValidationProvider>
        <div style="padding-top: 5px" class="buttons">
          <b-button
            type="is-primary"
            :disabled="invalid"
            @click="createEnvironment"
          >
            {{ $t('general-submit') }}
          </b-button>
        </div>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { createEnvironment } from '@/services/environments/index';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'NewEnvironment',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      password: null,
      username: null,
      production: false,
      name: null,
      friendlyName: null,
      isLoading: false,
      envOfscHelp: false,
      envFriendHelp: false,
      envProdHelp: false,
      envUserHelp: false,
      envPasswordHelp: false,
    };
  },
  created() {
    extend('validEnvName', {
      message: (field) => {
        let msg = field;
        msg += this.$t(' should only contain the env name, not the full URL');
        return msg;
      },
      validate: (value) => {
        if (value.includes('https:') || value.includes('http:')) {
          return false;
        }
        return true;
      },
    });
  },
  methods: {
    closeModal() {
      this.$parent.close();
    },

    async createEnvironment() {
      try {
        this.isLoading = true;
        await createEnvironment({
          customer: this.customer,
          friendlyName: this.friendlyName,
          name: this.name,
          username: this.username,
          password: this.password,
          production: this.production,
          userId: this.userId,
        });
        this.$emit('environment-created', true);
      } catch (err) {
        console.error('Failed to create environment', err);
        this.$emit('environment-creation-failed', err);
      } finally {
        this.isLoading = false;
        this.closeModal();
      }
    },
  },
});
</script>

<style scoped>
span.bold {
  font-weight: 700;
}
</style>
