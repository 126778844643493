var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"valobs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.$t('New Environment Details')))])]),_c('section',{staticClass:"modal-card-body"},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('span',{on:{"click":function($event){_vm.envOfscHelp = !_vm.envOfscHelp}}},[_c('b-icon',{attrs:{"icon":"question-circle","size":"is-small"}})],1),_c('b-tooltip',{attrs:{"label":_vm.$t(
            'The name of the OFSC environment - usually companyname, company.test or companyname.test2'
          ),"active":_vm.envOfscHelp,"always":"","multilined":"","position":"is-bottom","type":"is-dark"}},[_c('span',{staticClass:"bold"},[_vm._v("OFSC Environment Name")])]),_c('ValidationProvider',{attrs:{"name":"Environment OFSC mame","rules":"required|validEnvName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('p',{staticClass:"help is-danger",staticStyle:{"min-height":"1rem"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{on:{"click":function($event){_vm.envFriendHelp = !_vm.envFriendHelp}}},[_c('b-icon',{attrs:{"icon":"question-circle","size":"is-small"}})],1),_c('b-tooltip',{attrs:{"label":_vm.$t("environment-name-hint"),"active":_vm.envFriendHelp,"always":"","multilined":"","position":"is-right","type":"is-dark"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t('Friendly name')))])]),_c('ValidationProvider',{attrs:{"name":"Friendly name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.friendlyName),callback:function ($$v) {_vm.friendlyName=$$v},expression:"friendlyName"}}),_c('span',{staticClass:"help is-danger",staticStyle:{"min-height":"1rem"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{on:{"click":function($event){_vm.envProdHelp = !_vm.envProdHelp}}},[_c('b-icon',{attrs:{"icon":"question-circle","size":"is-small"}})],1),_c('b-tooltip',{attrs:{"label":_vm.$t(
            'Setting this to true will prevent the environment being available for data to be loaded into'
          ),"active":_vm.envProdHelp,"always":"","multilined":"","position":"is-right","type":"is-dark"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t('Prevent data loads/production')))])]),_c('b-field',{staticStyle:{"padding-top":"5px"}},[_c('b-switch',{model:{value:(_vm.production),callback:function ($$v) {_vm.production=$$v},expression:"production"}})],1),_c('span',{on:{"click":function($event){_vm.envUserHelp = !_vm.envUserHelp}}},[_c('b-icon',{attrs:{"icon":"question-circle","size":"is-small"}})],1),_c('b-tooltip',{attrs:{"label":_vm.$t('The application/username to use to connect to OFSC'),"active":_vm.envUserHelp,"always":"","multilined":"","position":"is-right","type":"is-dark"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t('OFSC Integration User/Application')))])]),_c('ValidationProvider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input',{attrs:{"name":"Username","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('span',{staticClass:"help is-danger",staticStyle:{"min-height":"1rem"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('span',{on:{"click":function($event){_vm.envPasswordHelp = !_vm.envPasswordHelp}}},[_c('b-icon',{attrs:{"icon":"question-circle","size":"is-small"}})],1),_c('b-tooltip',{attrs:{"label":_vm.$t("The password/secret to use to connect to OFSC"),"active":_vm.envPasswordHelp,"always":"","multilined":"","position":"is-right","type":"is-dark"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t('OFSC Integration Secret')))])]),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input',{attrs:{"name":"Password","type":"password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"help is-danger",staticStyle:{"min-height":"1rem"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"buttons",staticStyle:{"padding-top":"5px"}},[_c('b-button',{attrs:{"type":"is-primary","disabled":invalid},on:{"click":_vm.createEnvironment}},[_vm._v(" "+_vm._s(_vm.$t('general-submit'))+" ")])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }