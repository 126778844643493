<template>
  <div class="container" style="padding-top: 2rem">
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child box">
          <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
          <b-table
            v-if="environmentList && environmentList.length > 0"
            :data="environmentList"
            :paginated="true"
            :per-page="20"
            :pagination-simple="false"
            :selected.sync="selectedRow"
            @update:selected="rowClicked"
          >
            <b-table-column
              v-slot="props"
              field="friendlyName"
              sortable
              :label="$t('Friendly name')"
              >{{ props.row.friendlyName }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="name"
              label="Env. Name"
              sortable
              centered
            >
              {{ props.row.name }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              field="username"
              sortable
              centered
              :label="$t('Username')"
              >{{ props.row.username }}</b-table-column
            >
            <b-table-column
              v-slot="props"
              field="production"
              sortable
              centered
              :label="$t('Production?')"
              width="60"
            >
              <b-icon
                :icon="getIcon(props.row.production)"
                :type="getIconColor(props.row.production)"
              ></b-icon>
            </b-table-column>
          </b-table>
          <p v-else>{{ $t('No environments configured') }}</p>
          <br />
          <div class="buttons">
            <b-button type="is-primary" @click="fetchEnvironments(true)">
              {{ $t('Fetch environments') }}
            </b-button>
            <b-button type="is-primary" @click="showNewEnvironmentCard()">
              {{ $t('Add new environment') }}
            </b-button>
          </div>
          <br />
          <br />
          <b-notification v-if="error" type="is-danger" has-icon>
            {{ error.response.data }}
          </b-notification>
          <strong>
            <p>
              {{
                $t(
                  `Setting an environment to Production means it won't be available as a target in the data loaders`,
                )
              }}
            </p>
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import UpdateEnvironment from './UpdateEnvironment';
import NewEnvironment from './NewEnvironment';

export default defineComponent({
  data() {
    return {
      selectedEnvironment: null,
      selectedRow: null,
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.fetchEnvironments(true);
  },
  methods: {
    onEnvironmentUpdated() {
      this.fetchEnvironments(true);
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Environment updated successfully',
        type: 'is-success',
      });
    },
    onEnvironmentUpdateFailed(err) {
      this.error = err;
      this.fetchEnvironments(true);
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Environment update failed',
        type: 'is-danger',
      });
    },
    onEnvironmentDeleted() {
      this.fetchEnvironments(true);
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Environment deleted successfully',
        type: 'is-success',
      });
    },
    onEnvironmentDeleteFailed(err) {
      this.error = err;
      this.fetchEnvironments(true);
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Environment delete failed',
        type: 'is-danger',
      });
    },
    showNewEnvironmentCard() {
      this.error = null;
      this.$buefy.modal.open({
        parent: this,
        events: {
          'environment-created': () => {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Environment created successfully',
              type: 'is-success',
            });
            this.fetchEnvironments(true);
          },
          'environment-creation-failed': (error) => {
            this.error = error;
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Environment creation failed',
              type: 'is-danger',
            });
          },
        },
        component: NewEnvironment,
        hasModalCard: false,
        width: 960,
      });
    },
    showUpdateEnvironmentCard() {
      this.error = null;
      this.$buefy.modal.open({
        parent: this,
        props: {
          name: this.selectedEnvironment.name,
          friendlyName: this.selectedEnvironment.friendlyName,
          production: this.selectedEnvironment.production,
          username: this.selectedEnvironment.username,
          password: this.selectedEnvironment.password,
        },
        events: {
          'environment-deleted': this.onEnvironmentDeleted,
          'environment-delete-failed': this.onEnvironmentDeleteFailed,
          'environment-updated': this.onEnvironmentUpdated,
          'environment-update-failed': this.onEnvironmentUpdateFailed,
        },
        component: UpdateEnvironment,
        hasModalCard: false,
        width: 960,
      });
    },
    getIcon(value) {
      if (value) {
        return 'check';
      } else {
        return 'times';
      }
    },
    getIconColor(value) {
      if (value) {
        return 'is-success';
      } else {
        return 'is-danger';
      }
    },
    rowClicked(row) {
      this.selectedEnvironment = row;
      this.showUpdateEnvironmentCard();
    },
  },
});
</script>
