var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.$t('New Environment Details')))])]),_c('section',{staticClass:"modal-card-body"},[_c('form',[_c('b-field',{attrs:{"label":_vm.$t('OFSC environment name')}},[_c('b-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.nameLocal),callback:function ($$v) {_vm.nameLocal=$$v},expression:"nameLocal"}})],1),_c('b-field',{attrs:{"label":"Prevent data loads or production environment?"}},[_c('b-switch',{model:{value:(_vm.productionLocal),callback:function ($$v) {_vm.productionLocal=$$v},expression:"productionLocal"}})],1),_c('validation-provider',{attrs:{"rules":"required","name":"Friendly name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('Friendly name')}},[_c('b-input',{attrs:{"name":"Friendly Name","type":"text"},model:{value:(_vm.friendlyNameLocal),callback:function ($$v) {_vm.friendlyNameLocal=$$v},expression:"friendlyNameLocal"}})],1),_c('span',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('Username')}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.usernameLocal),callback:function ($$v) {_vm.usernameLocal=$$v},expression:"usernameLocal"}})],1),_c('span',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Key"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Key"}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},model:{value:(_vm.passwordLocal),callback:function ($$v) {_vm.passwordLocal=$$v},expression:"passwordLocal"}})],1),_c('span',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"disabled":!valid,"type":"is-primary"},on:{"click":_vm.updateEnvironment}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")]),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":_vm.deleteEnvironment}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }