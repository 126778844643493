<!--
  Component to display a selected environment in the admin screen
-->
<template>
  <validation-observer v-slot="{ valid }">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('New Environment Details') }}</p>
      </header>
      <section class="modal-card-body">
        <form>
          <b-field :label="$t('OFSC environment name')">
            <b-input v-model="nameLocal" type="text" disabled></b-input>
          </b-field>
          <b-field label="Prevent data loads or production environment?">
            <b-switch v-model="productionLocal"></b-switch>
          </b-field>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Friendly name"
          >
            <b-field :label="$t('Friendly name')">
              <b-input
                v-model="friendlyNameLocal"
                name="Friendly Name"
                type="text"
              ></b-input>
            </b-field>
            <span class="help is-danger">
              {{ errors[0] }}
            </span>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Username"
          >
            <b-field :label="$t('Username')">
              <b-input v-model="usernameLocal" type="text"></b-input>
            </b-field>
            <span class="help is-danger">
              {{ errors[0] }}
            </span>
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="required" name="Key">
            <b-field label="Key">
              <b-input
                v-model="passwordLocal"
                type="password"
                password-reveal
              ></b-input>
            </b-field>
            <span class="help is-danger">
              {{ errors[0] }}
            </span>
          </validation-provider>
        </form>
        <div class="buttons">
          <b-button
            :disabled="!valid"
            type="is-primary"
            @click="updateEnvironment"
          >
            {{ $t('Update') }}
          </b-button>
          <b-button type="is-danger" @click="deleteEnvironment">
            {{ $t('Delete') }}
          </b-button>
        </div>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {
  updateEnvironment,
  deleteEnvironment,
} from '@/services/environments/index';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'UpdateEnvironment',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    friendlyName: {
      type: String,
      required: true,
    },
    production: {
      type: Boolean,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameLocal: null,
      friendlyNameLocal: null,
      productionLocal: null,
      passwordLocal: null,
      usernameLocal: null,
    };
  },
  watch: {
    name: function (newValue) {
      this.nameLocal = newValue;
    },
    friendlyName: function (newValue) {
      this.friendlyNameLocal = newValue;
    },
    password: function (newValue) {
      this.passwordLocal = newValue;
    },
    production: function (newValue) {
      this.productionLocal = newValue;
    },
    username: function (newValue) {
      this.usernameLocal = newValue;
    },
  },
  mounted() {
    this.nameLocal = this.name;
    this.usernameLocal = this.username;
    this.friendlyNameLocal = this.friendlyName;
    this.productionLocal = this.production || false;
    this.passwordLocal = this.password;
  },
  methods: {
    async deleteEnvironment() {
      try {
        this.isLoading = true;
        deleteEnvironment({
          userId: this.userId,
          customer: this.customer,
          name: this.name,
        });

        this.$emit('environment-deleted');
        this.nameLocal = null;
        this.usernameLocal = null;
        this.friendlyNameLocal = null;
        this.productionLocal = false;
        this.passwordLocal = null;
        this.selectedEnvironment = null;
      } catch (err) {
        console.error('Failed to delete environment', err);
        this.$emit('environment-deletion-failed', err);
      } finally {
        this.isLoading = false;
        this.$parent.close();
      }
    },
    async updateEnvironment() {
      try {
        this.isLoading = true;
        const environmentPayload = {
          customer: this.customer,
          name: this.nameLocal,
          friendlyName: this.friendlyNameLocal,
          production: this.productionLocal,
          password: this.passwordLocal,
          username: this.usernameLocal,
          userId: this.userId,
        };
        await updateEnvironment(environmentPayload);
        this.$emit('environment-updated');
      } catch (err) {
        console.error('Failed to update environment', err);
        this.$emit('environment-update-failed', err);
      } finally {
        this.isLoading = false;
        this.$parent.close();
      }
    },
  },
});
</script>
